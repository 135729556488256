.lore-section {
  background-color: $light-blue-color;
  padding: 40rem 120rem 0;
  margin-top: -2rem;
  display: flex;
  gap: 72rem;
  position: relative;
  border-bottom: 2rem solid $dark-color;

  @media (max-width: $screen-lg) {
    padding: 30px 20px 0;
    gap: 32px;
  }

  .text-wrap {
    padding: 40rem 0;

    @media (max-width: $screen-lg) {
      padding: 20px 0 48px;
    }

    h2 {
      color: $dark-blue-color;
      margin-bottom: 16rem;

      @media (max-width: $screen-lg) {
        margin-bottom: 12px;
      }
    }

    .info-wrap {
      max-height: 260rem;
      overflow-y: auto;
      padding-right: 32rem;

      @media (max-width: $screen-lg) {
        max-height: 280px;
        padding-right: 100px;
      }

      &::-webkit-scrollbar {
        width: 16rem;

        @media (max-width: $screen-lg) {
          width: 10px;
        }
      }

      &::-webkit-scrollbar-track {
        border-radius: 10rem;
        border: 2rem solid $dark-color;
      }

      &::-webkit-scrollbar-thumb {
        background: $dark-blue-color;
        border-radius: 10rem;
      }
    }
  }

  .img-wrap {

    @media (max-width: $screen-lg) {
      position: absolute;
      right: 10px;
      bottom: 0;
    }

    img {
      width: 270rem;

      @media (max-width: $screen-lg) {
        width: auto;
        height: 220px;
      }
    }
  }
}
