@import url('https://fonts.googleapis.com/css2?family=Grandstander:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300..900&display=swap');

$text-font: 'Rubik', sans-serif;
$headline-font: 'Grandstander', cursive;

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

h1 {
  font: 700 72rem/1 $headline-font;

  @media (max-width: $screen-lg) {
    font-size: 56px;
  }

  @media (max-width: $screen-sm) {
    font-size: 40px;
  }
}

h2 {
  font: 700 64rem/1 $headline-font;

  @media (max-width: $screen-lg) {
    font-size: 52px;
  }

  @media (max-width: $screen-sm) {
    font-size: 36px;
  }
}

h3 {
  font: 600 28rem/1.1 $text-font;
  text-transform: uppercase;

  @media (max-width: $screen-lg) {
    font-size: 24px;
  }

  @media (max-width: $screen-sm) {
    font-size: 20px;
  }
}

p {
  font: 400 20rem/1.3 $text-font;

  @media (max-width: $screen-lg) {
    font-size: 18px;
  }

  @media (max-width: $screen-sm) {
    font-size: 16px;
  }
}
