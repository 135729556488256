.tokenomics-section {
  padding: 100rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom: 2rem solid $dark-color;

  @media (max-width: $screen-lg) {
    padding: 48px 20px;
  }

  .headline-wrap {
    text-align: center;
    margin-bottom: 30rem;

    h2 {
      color: $white-color;
    }
  }

  .items-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    max-width: 770rem;
    gap: 30rem;
    padding: 80rem;

    @media (max-width: $screen-lg) {
      background-color: $white-color;
      border: 2px solid $dark-color;
      max-width: 90%;
      border-radius: 30px;
      padding: 24px;
      gap: 24px;
    }

    .bg {
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      @media (max-width: $screen-lg) {
        display: none;
      }

      img {
        width: 100%;
      }
    }

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 280rem;
      height: 120rem;
      color: $white-color;
      text-align: center;
      border-radius: 15rem;
      background-color: $brown-color;

      @media (max-width: $screen-lg) {
        height: 90px;
        width: 100%;
        max-width: 290px;
      }
    }
  }
}
