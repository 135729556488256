header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;
  background: url('../../img/header-bg.svg') bottom center no-repeat;
  background-size: cover;
  height: 150rem;
  padding: 16rem 80rem 26rem;

  @media (max-width: $screen-lg) {
    flex-direction: column;
    justify-content: flex-start;
    height: 200px;
    padding: 30px;
  }

  .links-wrap {
    display: flex;
    align-items: center;
    gap: 40rem;

    @media (max-width: $screen-lg) {
      gap: 16px;
      margin-bottom: 20px;
    }

    a {
      font: 700 24em/1 $headline-font;
      color: $dark-color;
      text-decoration: none;

      @media (max-width: $screen-lg) {
        font-size: 20px;
      }

      &:hover {
        color: $dark-blue-color;
      }
    }
  }

  .logo-wrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 30rem;
    margin: auto;
    width: max-content;

    @media (max-width: $screen-lg) {
      position: relative;
      top: auto;
      margin: 60px auto 0;
    }

    img {
      width: 130rem;

      @media (max-width: $screen-lg) {
        width: 110px;
      }
    }
  }

  .socials-wrap {
    display: flex;
    align-items: center;
    gap: 20rem;

    @media (max-width: $screen-lg) {
      gap: 16px;
    }

    a {
      display: flex;
      text-decoration: none;

      &:hover {
        transform: scale(1.1);
        transition: $fast-transition;
      }

      &.disabled {
        pointer-events: none;
        opacity: .5;
      }

      img {
        width: 48rem;

        @media (max-width: $screen-lg) {
          width: 40px;
        }
      }
    }
  }
}
