.hero-section {
  display: flex;
  align-items: center;
  padding: 160em 80em 0;
  gap: 48rem;
  position: relative;

  @media (max-width: $screen-lg) {
    flex-direction: column-reverse;
    padding: 300px 20px 0;
    gap: 16px;
  }

  .img-wrap {
    position: relative;
    max-width: max-content;

    &:hover .img-hover {
      opacity: 1;
    }

    img {
      width: 600rem;

      @media (max-width: $screen-lg) {
        width: 100%;
        max-width: 500px;
      }
    }

    .img-hover {
      top: 0;
      left: 0;
      position: absolute;
      opacity: 0;
      transition: $fast-transition;

      @media (max-width: $screen-lg) {
        opacity: 1;
      }
    }
  }

  .text-wrap {
    display: flex;
    flex-direction: column;
    gap: 72rem;

    @media (max-width: $screen-lg) {
      gap: 32px;
    }

    .headline-wrap {

      @media (max-width: $screen-lg) {
        text-align: center;
      }

      h1 {
        color: $white-color;
        margin-bottom: 16rem;

        @media (max-width: $screen-lg) {
          margin-bottom: 12px;
        }
      }

      p {
        color: $light-blue-color;
        max-width: 440rem;

        @media (max-width: $screen-lg) {
          max-width: 440px;
        }

        span {
          font-weight: 600;
          color: $white-color;
        }
      }
    }

    .address-wrap {
      display: flex;
      flex-direction: column;
      gap: 12rem;

      @media (max-width: $screen-lg) {
        align-items: center;
        text-align: center;
        gap: 12px;
      }

      > p {
        color: $white-color;
      }

      .card-wrap {
        opacity: .8;
        padding: 15rem 20rem;
        border-radius: 15rem;
        max-width: max-content;
        border: 2rem solid $dark-color;
        background-color: $light-blue-color;

        @media (max-width: $screen-lg) {
          padding: 10px 15px;
          border-radius: 15px;
          border-width: 2px;
        }

        p {
          font-weight: 600;
        }
      }
    }
  }

  .before-wrap {
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 100%;

    @media (max-width: $screen-lg) {
      height: 100px;
    }

    img {
      width: 100%;

      @media (max-width: $screen-lg) {
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
