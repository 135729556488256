@import "header";
@import "hero";
@import "lore";
@import "tokenomics";
@import "footer";

:root {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

* {
  font-family: $text-font;
  margin: 0;
}

*, *::before, *::after {
  box-sizing: border-box;
}

html {
  font-size: calc(1 / 1440 * 100vw);

  @media (max-width: $screen-lg) {
    font-size: 1px;
  }
}

body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  background-color: $main-blue-color;
}

.animation {
  transition: opacity .5s ease-out, transform .5s ease-out;
  height: max-content;

  &:not(.animation-active) {
    opacity: 0;
    transform: translateY(10vh);
  }
}
